<template>
  <InsetPanel
      :title="t('permissionDenied')"
      class="permission-denied-panel">
    <Paragraph type="intro">
      {{ t('deniedIntro') }}
    </Paragraph>
    <ActionPane v-if="!user">
      <Paragraph v-if="!user">
        {{ t('noUser') }}
      </Paragraph>
      <template #buttons>
        <IconButton
            :to="{
          name: `${locale}:login`,
          query: {
            next: route.path
          }
        }"
            type="secondary">
          {{ t('login') }}
        </IconButton>
      </template>
    </ActionPane>
    <ActionPane v-else>
      <Paragraph>
        {{ t('currentUser', { user: user.email }) }}
      </Paragraph>
      <template #buttons>
        <IconButton
            :to="{
          name: `${locale}:home`,
        }"
            type="secondary">
          {{ t('toHome') }}
        </IconButton>
      </template>
    </ActionPane>
  </InsetPanel>
</template>
<i18n>
{
  "nl": {
    "deniedIntro": "De pagina die je bezoekt, mag je niet bekijken.",
    "currentUser": "Je bent nu ingelogd als {user}.",
    "noUser": "Je bent niet ingelogd.",
    "login": "Inloggen",
    "toHome": "Ga naar beginpagina",
    "permissionDenied": "Geen toegang"
  },
  "en": {
    "deniedIntro": "You are not allowed to view the page you are currently visiting.",
    "currentUser": "You are logged in as {user}.",
    "noUser": "You are not logged in.",
    "login": "Login",
    "toHome": "Go back to the home page",
    "permissionDenied": "No access"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import Paragraph from '../typography/Paragraph.vue';
import useSession from '../../session/session.js';
import InsetPanel from '../panels/InsetPanel.vue';
import IconButton from '../buttons/IconButton.vue';
import ActionPane from '../panels/ActionPane.vue';

export default {
  components: {
    ActionPane,
    IconButton,
    InsetPanel,
    Paragraph,
  },
  setup() {
    const { t, locale } = useI18n();
    const { user, sessionInitialised } = useSession();
    const route = useRoute();

    return {
      t,
      locale,

      route,

      user,
      sessionInitialised,

    };
  },
};
</script>
<style>
.permission-denied-panel {
  align-self: center;

  max-width: var(--dimension-single-column);
  justify-self: center;
}
</style>

<template>
  <div
      class="card"
      :class="{'card--interactive': interactive, 'card--selected': selected, [`card--${type}`]: !!type}">
    <LoaderPane :loading="loading">
      <header
          v-if="!!$slots.header || !!$slots.title || title"
          class="card__header">
        <slot name="header">
          <CardHeader>
            <template #title>
              <slot name="title">
                <CardTitle>{{ title }}</CardTitle>
              </slot>
            </template>
            <template
                #actions>
              <slot name="actions" />
            </template>
          </CardHeader>
        </slot>
      </header>
      <slot name="content">
        <div class="card__content">
          <slot />
        </div>
      </slot>
    </LoaderPane>
  </div>
</template>
<script>
import LoaderPane from '../loading/LoaderPane.vue';
import CardHeader from './CardHeader.vue';
import CardTitle from './CardTitle.vue';

export default {
  components: { CardTitle, LoaderPane, CardHeader },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    interactive: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.card {
  --card-image: unset;
  --card-color: var(--color-primary-background);
  --card-style: solid;
  --card-background: var(--color-default-background);
  --card-foreground: var(--color-default-foreground);
  --card-loader-color: color-mix(in srgb, var(--card-color), var(--color-default-background) 75%);
  --card-hover-background: color-mix(in srgb, var(--card-color), var(--color-default-background) 90%);
  --card-selected-background: color-mix(in srgb, var(--card-color), var(--color-default-background) 65%);
  --loader-color: var(--card-loader-color);
  --card-border-color: var(--card-color);
  display: grid;
  grid-template-rows: auto 1fr;

  overflow: hidden;

  color: var(--card-foreground);
  background-color: var(--card-background);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-image: var(--card-image);
  box-shadow: 0 .25rem 1rem -.125rem rgba(var(--rgb-black), .1);

  transition: box-shadow var(--animation-default-duration), background-color var(--animation-default-duration), border-image var(--animation-default-duration);

  &--interactive {
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--card-hover-background);

        box-shadow: 0 .125rem .5rem -.125rem rgba(var(--rgb-black), 30%);
      }

    }
    border-left: .25rem var(--card-style) var(--card-border-color);
    cursor: pointer;
  }

  &--selected {
    background-color: var(--card-selected-background);
  }

  &__content {
    display: grid;

    padding: var(--dimension-small);

  }
}
</style>

<template>
  <div
      :class="{
      'form-field--errors': validator?.$error || errors.length > 0,
      'form-field--disabled': !enabled,
      'form-field--start': !flex,
      'form-field--label': label || !!$slots.label,
      'form-field--wide-errors': wideErrors,
      'form-field--sized': !!size,
      [`form-field--${type}`]: true,
    }"
      class="form-field">
    <div class="form-field__widget">
      <label
          v-if="label || !!$slots.label"
          :for="id"
          :class="{'form-field__label--required': required}"
          class="form-field__label">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
      <div
          ref="input"
          :style="(size) ? {'max-width': `${size}ch`} : {}"
          class="form-field__input">
        <slot
            :id="id"
            :enabled="enabled"
            :validator="validator"
            :field-name="name" />
      </div>
    </div>
    <transition name="animation-cross-fade">
      <div
          v-if="hasErrors"
          class="form-field__errors">
        <ul class="form-field__error">
          <slot
              name="error"
              :validator="validator" />
          <li
              v-for="error in errors"
              :key="error">
            {{ error }}
          </li>
        </ul>
      </div>
    </transition>
    <div
        v-if="helpText || $slots['help-text']"
        class="form-field__help-text">
      <slot name="help-text">
        <p v-if="helpText">
          {{ helpText }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  computed,
  inject,
  toRefs,
} from 'vue';

export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    size: {
      type: Number,
      default: () => null,
    },
    type: {
      type: String,
      default: () => 'default',
    },
    helpText: {
      type: String,
      default: () => '',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    wideErrors: {
      type: Boolean,
      default: () => false,
    },
    validator: {
      type: Object,
      default: () => null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    flex: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const { name, validator, errors } = toRefs(props);
    const baseName = inject('baseFieldName', { value: null });
    return {
      id: computed(() => `${baseName.value || 'id'}–${name.value || 'input'}`),

      hasErrors: computed(() => validator.value?.$error || errors.value?.length > 0),
    };
  },
};
</script>

<style>

.form-field {
  --form-field-inset-width: 9rem;
  --form-field-input-width: auto;
  --form-field-inset: 9rem;
  --form-field-orientation: row;
  --form-field-label-align: flex-end;
  --form-field-label-align-opposite: flex-start;
  @media screen and (max-width: 512px) {
    & {
      --form-field-orientation: column;
      --form-field-inset: auto;
      --form-field-inset-width: 0rem;
      --form-field-label-align: flex-start;
      --form-field-label-align-opposite: flex-start;
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;

  max-width: 100%;

  &--start {
    justify-self: flex-start;
  }

  &--disabled {
    opacity: .4;
    filter: saturate(25%);

    pointer-events: none;
  }

  &__widget {
    display: flex;
    flex-direction: var(--form-field-orientation);
    justify-self: flex-start;

    max-width: var(--dimension-input-width);

    background-color: var(--color-background);
    border-bottom: 1px solid var(--color-primary-background);

    &:focus-within {
      background-color: color-mix(in srgb, var(--color-background), var(--color-default-foreground) 5%);
    }
  }

  &--wide &__widget {
    flex-direction: column;
  }

  &__input {
    display: grid;
    flex: 1;

    width: var(--form-field-input-width);
  }

  &__label {
    display: flex;
    flex-direction: row;
    justify-content: var(--form-field-label-align);
    align-items: flex-start;

    width: var(--form-field-inset);
    padding: var(--dimension-small);

    background-color: rgba(var(--rgb-default-foreground), .05);

    cursor: pointer;

    font-size: .75rem;
    font-weight: 400;
    line-height: 1rem;

    text-align: right;
    text-transform: uppercase;

    &--required {
      font-weight: 600;
    }
  }

  &--wide &__label {
    justify-content: var(--form-field-label-align-opposite);

    width: auto;

    text-align: left;
  }

  &__errors, &__help-text {
    max-width: var(--dimension-input-width);
    padding: var(--dimension-x-small);

    font-size: .75rem;
    line-height: 1.1;
  }

  &__errors {
    color: var(--color-default-foreground);
    background-color: var(--color-red);
  }

  &__help-text {
    max-width: 60ch;

    color: color-mix(in srgb, var(--color-default-foreground), var(--color-default-background) 50%);
  }

  &--label &__help-text, &--label &__errors {
    padding-left: calc(var(--form-field-inset-width) + var(--dimension-x-small));

  }

  &--wide &__help-text, &--wide &__errors, &--wide-errors &__errors {
    padding-left: var(--dimension-x-small);
  }

  &--sized &__widget {
    align-self: flex-start;
  }

  &--sized &__input {
    width: auto;
  }
}
</style>

import { reactive } from 'vue';

export default function useTableSort(columns, itemsRef) {
  return {
    columns: reactive(Object.fromEntries(Object.entries(columns).map(
      ([name, { label, compare }]) => [
        name, {
          label,
          compare,
          ascending: null,
          select() {
            return true;
          },
        },
      ],
    ))),
    items: itemsRef,
  };
}

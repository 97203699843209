<template>
  <router-link
      v-slot="{ navigate, isActive, href }"
      custom
      :to="to">
    <InteractiveTableRow
        v-bind="$attrs"
        class="navigation-table-row"
        :class="{'navigation-table-row--active': isActive}">
      <a
          :href="href"
          class="navigation-table-row__link"
          @click="navigate">
        <slot />
      </a>
    </InteractiveTableRow>
  </router-link>
</template>
<script>

import InteractiveTableRow from './InteractiveTableRow.vue';

export default {
  components: { InteractiveTableRow },
  inheritAttrs: false,
  props: {
    to: {
      type: Object,
      default: () => null,
    },
  },

};
</script>
<style>
.navigation-table-row {
  @media (any-hover: hover) {
    &:hover {
      color: var(--color-default-foreground);
      background-position: 0 100%;
    }
  }

  &__link {
    display: contents;

    color: inherit;
  }
}
</style>

import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
  toRef,
  watch,
  watchEffect,
} from 'vue';

const toggleAttribute = (el, attribute, value) => {
  if (value) {
    el.setAttribute(attribute, `${value}`);
  } else {
    el.removeAttribute(attribute);
  }
};

const isElementOrChild = (a, b) => a && b && (a === b || isElementOrChild(a, b.parentNode));

export default function useSortable(
  root,
  handle,
  item,
  contentType,
  draggable = true,
  anchored = null,
  context = null,
) {
  const rootRef = toRef(root);
  const handleRef = toRef(handle);
  const draggableRef = toRef(draggable);
  const anchoredRef = toRef(anchored);
  const itemRef = toRef(item);
  const contentTypeRef = toRef(contentType);
  const isDragging = ref(false);
  const isSelected = computed({
    get() {
      return context.value?.items.value.some((i) => i.id === item.value?.id);
    },
    set(v) {
      if (v) {
        context.value?.add(contentType.value, item.value);
      } else {
        context.value?.remove(contentType.value, item.value);
      }
    },
  });
  const selectedIndex = computed(() => {
    const idx = context.value?.items.value.findIndex((i) => i.id === item.value?.id);
    return (idx === -1) ? false : idx;
  });
  const dragOffset = ref(null);

  let target = null;

  const clickStart = (ev) => {
    if (draggableRef.value) {
      target = ev.target;
    }
  };

  const clickEnd = () => {
    isSelected.value = !isSelected.value;
  };

  const dragStart = (ev) => {
    const handleEl = handleRef.value?.$el || handleRef.value;

    if (handleEl && target && !isElementOrChild(handleEl, target)) {
      ev.preventDefault();
    } else {
      // eslint-disable-next-line no-param-reassign
      ev.dataTransfer.effectAllowed = 'move';
      ev.dataTransfer.setData(`application/x-cymbal-${contentTypeRef.value}`, JSON.stringify({
        contentType: contentTypeRef.value,
        item: itemRef.value,
      }));
      isDragging.value = true;
      dragOffset.value = ev.layerY;
      context.value?.set(contentType.value, item.value, dragOffset.value);

      if (handleEl) {
        handleEl.classList.add('draggable-handle--dragging');
      }

    }
  };

  const dragEnd = () => {
    isDragging.value = false;
    isSelected.value = false;
    context.value?.cancel();
    target = null;
    const handleEl = handleRef.value?.$el || handleRef.value;
    if (handleEl) {
      handleEl.classList.remove('draggable-handle--dragging');
    }
  };

  onMounted(() => {
    const rootEl = rootRef.value?.$el || rootRef.value;

    if (rootEl) {
      rootEl.addEventListener('dragstart', dragStart);
      rootEl.addEventListener('dragend', dragEnd);

      rootEl.addEventListener('mousedown', clickStart);
      rootEl.classList.add('draggable-handle-root');

      watchEffect(() => {
        toggleAttribute(rootEl, 'draggable', draggableRef.value);
        rootEl.dataset.draggableAnchored = anchoredRef.value;
        rootEl.dataset.draggableContentType = contentTypeRef.value;
        rootEl.dataset.draggableContentId = item.value?.id;
      });
    }

  });

  onUpdated(() => {
    const rootEl = rootRef.value?.$el || rootRef.value;
    if (rootEl) {
      toggleAttribute(rootEl, 'draggable', draggableRef.value);
      rootEl.classList.add('draggable-handle-root');
    }

  });

  onBeforeUnmount(() => {
    const rootEl = rootRef.value?.$el || rootRef.value;
    const handleEl = handleRef.value?.$el || handleRef.value;
    if (handleEl) {
      handleEl.removeEventListener('mouseup', clickEnd);
    }
    if (rootEl) {
      rootEl.removeAttribute('draggable');
      rootEl.removeEventListener('dragstart', dragStart);
      rootEl.removeEventListener('dragend', dragEnd);

      rootEl.removeEventListener('mousedown', clickStart);
      rootEl.classList.remove('draggable-handle-root');
    }
  });

  watch(handleRef, (n, o) => {
    if (n && n !== o) {
      const handleEl = n.$el || n;
      if (handleEl) {
        handleEl.addEventListener('mouseup', clickEnd);
      }
    } else if (n !== o) {
      const handleEl = o.$el || o;
      if (handleEl) {
        handleEl.removeEventListener('mouseup', clickEnd);
      }
    }
  });

  return {
    isDragging, dragOffset, isSelected, selectedIndex,
  };
}
